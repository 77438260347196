import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { GetVerificationCode, Mail, SendVerificationCode, VeriCode, CodeLoading, Access } from '../../store/slices/ForgetPassSlice'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
const Verification = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const code = useSelector(VeriCode);
    const lastMail = useSelector(Mail);
    const veriLoading = useSelector(CodeLoading);
    const accessToNavigate = useSelector(Access);

    const navigate = useNavigate();

    const [sendTimer, setSendTimer] = useState(0);
    const [clickTime, setClickTimes] = useState(0);
    const [loggedInMSG, setLoggedInMSG] = useState(false);
    const [accessToSend, setAccessToSend] = useState(false);
    const [saveDT, setSaveDT] = useState(false);
    const [err, setErr] = useState(null);
    const [verificationCode, setverificationCode] = useState('')

    const TimerOfSendingCode = () => {
        const duration = Math.min(clickTime * 60, 180);
        setAccessToSend(true);

        const timer = setInterval(() => {
            setSendTimer((last) => {
                if (last + 1 === duration) {
                    clearInterval(timer);
                    setAccessToSend(false);
                    return 0;
                }
                return last + 1;
            });
        }, 1000);
        return () => clearInterval(timer)
    };

    const CheckCode = async (e) => {
        e.preventDefault();
        try {
            await dispatch(GetVerificationCode({
                veriCode: verificationCode,
                veriMail: lastMail
            }))
        } catch (err) {
            setErr(err)
        }
    }


    useEffect(() => {
        const ResendCode = async () => {
            try {
                await dispatch(SendVerificationCode(lastMail))
            } catch (err) {
                setErr(err || 'Try Again')
            }
        }

        if (accessToSend) {
            ResendCode();
        }
        // if(clickTime === 4){   // افتحها لو عايز تتباكس عادي
        //     navigate('/')
        // }
        if (accessToNavigate) {
            navigate('/forget-password/new-password')
        }
    }, [accessToSend, lastMail, accessToNavigate, dispatch, navigate]);

    useEffect(() => {
        const AlertWhenRefresh = (event) => {
            if (!saveDT || verificationCode !== '') {
                const confirmationMessage = "Are you sure you want to leave this page? Unsaved changes will be lost.";
                event.returnValue = confirmationMessage;
                return confirmationMessage;
            }
        };

        window.addEventListener('beforeunload', AlertWhenRefresh);

        return () => {
            window.removeEventListener('beforeunload', AlertWhenRefresh);
        };
    }, [saveDT, verificationCode]);

    useEffect(() => {
        if (Cookies.get('token')) {
            setLoggedInMSG(true)
            const loggedInTimer = setTimeout(() => {
                navigate('/profile')
            }, 4000)
            return () => clearTimeout(loggedInTimer)
        }
        if (!code) {
            navigate('/')
        }
    }, [navigate, code])


    return (

        <>
            {/* للبكاسه */}
            {/* {
            clickTime === 4 && <div className='text-center text-5xl my-14 font-black'>YOU REACHED LIMITED CODE CLICK 👋</div>
        } */}
            {
                !loggedInMSG
                &&
                <main className="py-5 px-6 mob:px-2">
                    <div className="flex flex-col items-center w-fit mx-6 tab:mx-0">
                        <h1 className="f-ex-bold text-2xl ">{t('forgetPassIntro')}</h1>
                        <div>
                            <img src="/imgs/curve.png" alt="curve" className="w-auto" />
                        </div>
                    </div>
                    <section className="flex justify-center items-center my-5">
                        <section className="bg-[#F7F7FA] my-22 mx-auto p-4 shadow-md border rounded w-[43rem] tab:w-full">
                            <p className="f-semi-bold mx-3 my-2 mb-5 text-gray-500 w-[23rem] mob:w-auto">
                                {t('orderTwo')}
                            </p>
                            <form method="POST" onSubmit={CheckCode} className="pr-2">

                                <div className="px-2">
                                    <label htmlFor="code" className="f-bold text-xl">
                                        {t('code')}
                                    </label>
                                    <br />
                                    <input
                                        type="number"
                                        id="code"
                                        name="number"
                                        value={verificationCode}
                                        onChange={(e) => setverificationCode(e.target.value)}
                                        placeholder={t('writeCode')}
                                        className="my-2 border rounded-sm outline-none f-rg px-2 py-1 shadow-sm w-full"
                                        required
                                        aria-required={true}
                                    />
                                </div>
                                <div className='flex items-center justify-between w-full mob:flex-col mob:gap-2 my-4'>
                                    <button className="submit-btn" type="submit">
                                        {
                                            veriLoading ? (
                                                <div className="flex justify-center">
                                                    <span className="mx-2 f-bold"
                                                    >{t("plsWait")}</span>
                                                    <div className="animate-spin rounded-full border-t-4 border-b-4 border-white h-7 w-7 mr-2"></div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <span className="mx-2 f-bold">{t('confirmCode')}</span>{" "}
                                                </div>
                                            )}
                                    </button>

                                    <button className='submit-btn f-bold'
                                        onClick={() => {
                                            if (clickTime < 4) { // الباكس اخرو 4 مرات بس 
                                                if (verificationCode !== '') {
                                                    TimerOfSendingCode();
                                                    setClickTimes((last) => last + 1);
                                                    setSaveDT(true)
                                                }
                                            }
                                        }}
                                        disabled={sendTimer > 0 || clickTime >= 4} // نورتنا يا بكس
                                    >
                                        {
                                            sendTimer > 0 ? (
                                                <>
                                                    {t('waitFor')} {sendTimer} {t('seconds')}
                                                </>
                                            ) : (
                                                t('resendCode')
                                            )
                                        }

                                    </button>
                                </div>
                            </form>
                        </section>
                        {
                            (err) && <div className="text-xl text-center text-red-500 font-bold f-bold my-4">
                                <p>404 | ERROR</p>
                                <p>{err}</p>
                            </div>
                        }
                    </section>

                </main>
            }
        </>
    )
}
export default Verification