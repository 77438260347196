import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { HomeLoading, SettingsSelector } from "../../store/slices/HomeSlice";
import LoadingComponent from "../../UI/CustomUI/LoadingComponent";
import { useTranslation } from "react-i18next";

export default function Footer() {
    const { t } = useTranslation();
    const settings = useSelector(SettingsSelector);
    const loading = useSelector(HomeLoading);

    if (loading)
        return (
            <LoadingComponent
                parentClass={`grid grid-cols-4 gap-5 tab:grid-cols-2 mob:grid-cols-1 p-5 tab:gap-0`}
                childClass={`w-full h-[10rem] rounded-lg`}
                childrenNumber={4}
            />
        );
    return (
        <footer className="overflow-hidden">
            <main className="bg-black px-40 py-28 tab:px-5">
                <main className="flex flex-col h-fit gap-5 tab:gap-0">
                    <section className="grid grid-cols-4 gap-5 tab:grid-cols-2 mob:grid-cols-1 tab:gap-0">
                        <section className="m-0 p-0">
                            <div>
                                <Link to={`/`}>
                                    <h1 className="f-bold text-2xl text-white">
                                        <span className="text-red-500 f-bold text-2xl">DULIJAN </span>
                                        ACADEMY
                                    </h1>
                                </Link>
                            </div>
                            <div>
                                <p className="f-rg text-gray-400 my-4">
                                    {t("demo.0")}
                                </p>
                            </div>
                            <div className="f-rg text-gray-400 my-2">
                                <p className="my-4">
                                    463 7th Ave, NY 10018, KSA
                                </p>
                                <a href={`tel:+${settings?.phone}`} dir="ltr">
                                    +{settings?.phone?.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, "$1 $2 $3 $4")}
                                </a>
                            </div>
                        </section>

                        <section className="m-0 p-0">
                            <h2 className="f-bold text-white footerH2 w-fit">{t("usefulLinks")}</h2>
                            <span className="text-gray-400 hover:text-white">
                                <a href="#." className="f-rg">
                                    {t("advisor")}
                                </a>
                            </span>
                        </section>

                        <section className="m-0 p-0">
                            <h2 className="f-bold text-white footerH2 w-fit">{t("aboutAcademy")}</h2>
                            <ul className="text-gray-400 p-0">
                                <li className="hover:text-white w-fit">
                                    <a href={`tel:+${settings?.phone}`} className="f-rg">
                                        {t("callus")}
                                    </a>
                                </li>
                                <li className="hover:text-white w-fit">
                                    <a href="#." className="f-rg">
                                        {t("privacy")}
                                    </a>
                                </li>
                                <li className="hover:text-white w-fit">
                                    <a href="#." className="f-rg">
                                        {t("terms")}
                                    </a>
                                </li>
                            </ul>
                        </section>

                        <section className="m-0 p-0">
                            <h2 className="f-bold text-white footerH2 w-fit">{t("contactUs")}</h2>
                            <span className="text-gray-400">
                                <a href="#." className="f-rg">
                                    {t("demo.0")}
                                </a>
                            </span>
                            <div className="flex items-center gap-2 my-4">
                                <i className="fab fa-facebook" style={{ color: "#F5F5F5", width: "1.5rem", height: "1.5rem" }}></i>
                                <i className="fab fa-instagram" style={{ color: "#F5F5F5", width: "1.5rem", height: "1.5rem" }}></i>
                                <i className="fab fa-whatsapp" style={{ color: "#F5F5F5", width: "1.5rem", height: "1.5rem" }}></i>
                                <i className="fab fa-twitter" style={{ color: "#F5F5F5", width: "1.5rem", height: "1.5rem" }}></i>
                            </div>
                        </section>
                    </section>

                    <div>
                        <div className="bg-[#181818] rounded-md flex items-center justify-between p-2 w-[22rem] tab:w-full">
                            <input
                                type="email"
                                title={t("enterEmail")}
                                tabIndex="0"
                                pattern="\d+"
                                placeholder={t("enterEmailPlaceholder")}
                                className="text-white bg-[#181818] w-full outline-none text-lg rounded-md f-bold px-2 py-2"
                            />
                            <i className="fa-solid fa-paper-plane bg-white text-red-500 transform p-2 rounded-md" id="send"></i>
                        </div>
                    </div>
                </main>

            </main>
            <div className="bg-[#FB0100] p-4">
                <p className="f-bold text-xl">
                    {t("allRightsReserved")}
                </p>
            </div>
        </footer>
    );
}
