import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import PackageSwiper from "./packageSwiper";
import { Link } from "react-router-dom";
import CourseBox from "../../UI/CoursesUI/courseBox";
import IsTabletOrLarger from "../../hooks/UIHooks/isTabletOrLarger";
import { useTranslation } from "react-i18next";

const PackagesContent = ({ courses }) => {
    const isTabletOrLarger = IsTabletOrLarger('(min-width: 1000px)')
    const swiperRef = useRef(null)
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        if (swiperRef.current) {
            const swiper = swiperRef.current.swiper;

            const updateButtonState = () => {
                setIsBeginning(swiper.isBeginning);
                setIsEnd(swiper.isEnd);
            };

            swiper.on('slideChange', updateButtonState);
            updateButtonState();
        }
    }, []);
    return (
        <>
            <main className="mainBg">
                <main className="p-[1.5rem] mob:p-2">
                    <div className="flex justify-between w-full my-5 mob:flex-col mob:gap-4 tab:px-3">
                        <div className="flex flex-col items-center w-fit tab:items-start">
                            <h1 className="f-bold text-2xl ">{t("packages")}</h1>
                            <div><img src="/imgs/curve.png" alt="curve" className="w-auto" /></div>
                        </div>
                        <div className="text-gray-500">
                            <Link to={`/packages`} className="f-bold text-xl mob:text-lg">
                                {t("watchMore")}
                            </Link>
                        </div>
                    </div>
                    <section className="my-10">
                        <PackageSwiper
                            boxClassName="d-grid gap-4 mx-2"
                            slidesPerView={isTabletOrLarger ? 3 : 1}
                            seeFavourite={true}
                        />
                    </section>
                </main>
            </main>
            <main className="p-[1.5rem] my-10 mob:p-2">
                <div className="flex justify-between w-full my-5 mob:flex-col mob:gap-4 tab:px-3">
                    <div className="flex flex-col items-center w-fit tab:items-start">
                        <h1 className="f-bold text-2xl ">{t('courses')}</h1>
                        <div><img src="/imgs/curve.png" alt="curve" className="w-auto" /></div>
                    </div>
                    <div className="text-gray-500 ">
                        <Link to={`/courses`} className="f-bold text-xl mob:text-lg">
                            {t("watchMore")}
                        </Link>
                    </div>
                </div>
                <section className="flex items-center gap-4 my-10">
                    <button onClick={() => {
                        if (swiperRef.current) {
                            const swiper = swiperRef.current.swiper;
                            swiper.slidePrev();
                        }
                    }}
                        className={`swiper-btn ${isBeginning ? 'opacity-40 cursor-not-allowed' : ''}`}
                        disabled={isBeginning}
                    >
                        {
                            localStorage.getItem('i18nextLng') === 'en' ? <img src="/icons/left-arrow.svg" alt="Left Arrow" /> : <img src="/icons/right-arrow.svg" alt="Right Arrow" />
                        }
                    </button>

                    <Swiper
                        ref={swiperRef}
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        pagination={{ clickable: false }}
                        slidesPerView={isTabletOrLarger ? 3 : 1}
                        scrollbar={{ draggable: true }}
                        className="w-full"
                        spaceBetween={30}
                    >
                        {
                            courses.map((course, courseIndex) => (
                                <SwiperSlide key={courseIndex}>
                                    <CourseBox course={course} key={courseIndex} className={`w-[22.25rem] h-[13.6875rem] object-cover tab:w-full`} />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>

                    <button onClick={() => {
                        if (swiperRef.current) {
                            const swiper = swiperRef.current.swiper;
                            swiper.slideNext();
                        }
                    }} className={`swiper-btn ${isEnd ? 'opacity-40 cursor-not-allowed' : ''}`}
                        disabled={isEnd}
                    >
                        {
                            localStorage.getItem('i18nextLng') === 'en' ? <img src="/icons/right-arrow.svg" alt="Right Arrow" /> : <img src="/icons/left-arrow.svg" alt="Left Arrow" />
                        }
                    </button>
                </section>
            </main>
        </>
    );
};

export default PackagesContent;