import { useTranslation } from "react-i18next"

const FalseValidate = () => {
    const { t } = useTranslation();
    const details = [
        t('payDemoFail'), t('payDemoFail'), t('payDemoFail'), t('payDemoFail'), t('payDemoFail'), t('payDemoFail'), t('payDemoFail'), t('payDemoFail'), t('payDemoFail'), t('payDemoFail')
    ]
    return (
        <section className="bg-[#F5F5F5] p-4 px-14 rounded-lg w-[49rem] tab:w-[32rem] mob:w-full" dir="rtl">
            <div className="grid  justify-items-center">
                <div><img src="/icons/failed.svg" alt="success" /></div>
                <div>
                    <p className="f-bold text-xl">{t('payFail')}</p>
                </div>
            </div>
            <hr />
            <div className="flex flex-col items-center w-fit my-3 ">
                <h1 className="f-ex-bold text-lg">{t('details')}</h1>
                <div><img src="/imgs/curve.png" alt="curve" className="w-32" /></div>
            </div>
            <div>
                <ul>
                    {
                        details.map((detail, index) => (
                            <li key={index} className="f-semi-bold my-2 mob:text-base">
                                {'\u25A0'} {detail}
                            </li>
                        ))
                    }
                </ul>
            </div>
            <button className="mt-20 flex items-center gap-3">
                <span>
                    <img src="/icons/retry_svgrepo.com.svg" alt="retry" className="w-6" />
                </span>
                <span className="text-2xl f-bold text-red-500">
                    {t('tryAgain')}
                </span>
            </button>
        </section>
    )
}
export default FalseValidate