export default function ReviewBox({ review }) {
    const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
    return (
        <div className="py-7 grid px-3 border-2 border-black w-[26.25rem] h-[22.5rem] tab:w-full">
            <div>
                {
                    [...Array(review?.rate)].map((_, index) => (
                        <i className="fa-solid fa-star" style={{ color: '#FFD43B' }} key={index}></i>
                    ))}
            </div>
            <div>
                <p className="f-rg text-gray-500 text-sm tab:text-base">{review?.comment}</p>
            </div>
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                    <div>
                        <img src={`${IMG_URL}${review?.user?.image}`} alt={`${review?.user?.first_name}-profile-img`} className="w-14 rounded-full h-14" />
                    </div>

                    <div>
                        <h2 className="f-bold text-sm tab:text-base m-0">
                            <span className="mx-1">
                                {review?.user?.first_name}
                            </span>
                            <span>
                                {review?.user?.last_name}
                            </span>
                        </h2>
                        <h4 className="f-rg text-gray-500 text-sm">مشترك</h4>
                    </div>
                </div>
                <div>
                    <img src="/icons/comma.svg" alt="review" className="w-50" />
                </div>
            </div>
        </div>
    )
}