import { useEffect, useState } from "react"
import SubscriptionsBox from "../UI/SubscriptionsUI/SubscriptionsBox"
import NavComponent from "../UI/CustomUI/NavComponent"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { profileDataFetching, RemoveToken, UserCoursesSelector, UserErr, UserLoading, UserPackagesSelector, UserProfileSelector, UserToken } from "../store/slices/ProfileSlice"
import LoadingComponent from "../UI/CustomUI/LoadingComponent"
import Cookies from 'js-cookie'
import { useTranslation } from "react-i18next"
import EmptySubscriptions from "../UI/SubscriptionsUI/EmptySubscriptions"
import Notifications from "../components/ProfileComponets/Notifications"

const Profile = () => {
    const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [notFound, setNotFound] = useState(false);
    const { t } = useTranslation();

    const user = useSelector(UserProfileSelector);
    const userToken = useSelector(UserToken);
    const userCourses = useSelector(UserCoursesSelector);
    const userPackages = useSelector(UserPackagesSelector);
    const userLoading = useSelector(UserLoading);
    const userErr = useSelector(UserErr);
    const [secState, setSecState] = useState('packages');
    const [seeNotifications, setSeeNotifications] = useState(false);

    useEffect(() => {
        if (userToken || Cookies.get("token")) {
            dispatch(profileDataFetching());
        } else {
            setNotFound(true);
        }
    }, [dispatch, userToken]);

    const LogOut = () => {
        dispatch(RemoveToken());
        navigate('/');
        localStorage.setItem('userIn', false);
    }

    const ToggleNotifications = () => {
        setSeeNotifications(prev => !prev)
    }



    if (userLoading) return <LoadingComponent parentClass={`grid w-full p-5 gap-2 justify-items-center`} childClass={[
        'h-[5rem] w-20 rounded-full',
        'h-[2rem] w-32 rounded-lg',
        'h-[5rem] w-full mt-8 rounded-lg',
        'h-[5rem] w-full rounded-lg',
        'h-[5rem] w-full rounded-lg',
    ]} childrenNumber={5} />

    if (userErr || notFound) return <div className="f-bold text-red-500 text-center text-2xl py-24">
        <p>{t('sorryNoUser')}</p>
        <div className="f-bold text-blue-500 underline">
            <Link to={`/login`}>
                {t('canLoginHere')}
            </Link>
        </div>
    </div>
    return (
        <>
            {/* {
                seeNotifications && <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-[1500]"
                    onClick={ToggleNotifications}
                />
            }
            <section className={`z-[2000] bg-white w-full transition-all duration-200 ${seeNotifications ? 'bottom-0 opacity-100 fixed' : '-bottom-full absolute opacity-50'}`}>
                <Notifications />
            </section> */}
            <NavComponent firstName={t("profile")} firstURL={`/profile`} />
            <main className="relative px-12 mob:px-5">
                <div className="absolute right-20 mob:right-16">
                    {/* NOTIFICATIONS */}
                    {/* <div className="cursor-pointer my-2 relative" onClick={ToggleNotifications} title={t('notification')}>
                        <span className="w-2 h-2 rounded-full block bg-red-500 absolute right-[0.58rem]"></span>
                        <i className="fa-regular fa-bell fa-xl text-[#ff0000]"></i>
                    </div> */}
                    {/* EDIT PROFILE */}
                    <Link to={`/profile/settings`} title={t('profileSettings')}>
                        <i className="fa-regular fa-pen-to-square fa-xl text-[#ff0000]"></i>
                    </Link>
                    {/* LOGOUT  */}
                    <div onClick={LogOut} className="cursor-pointer my-2" title={t('logout')}>
                        <i className="fa fa-sign-out fa-xl text-[#ff0000]" aria-hidden="true"></i>
                    </div>
                </div>
                <section className="my-10">
                    <div className="grid items-center gap-3 justify-items-center mb-8">
                        <div className="rounded-full">
                            <img src={`${IMG_URL}${user?.image}`} alt="profile" className=" w-44 h-44 rounded-full" />
                        </div>
                        <div>
                            <h1 className="f-bold ">{user?.first_name} {user?.last_name}</h1>
                        </div>
                    </div>
                    <hr />
                    <section className="my-8">
                        <div className="flex flex-col items-center w-fit">
                            <h1 className="f-ex-bold text-2xl ">{t('subscriptions')}</h1>
                            <div><img src="/imgs/curve.png" alt="curve" className="w-auto" /></div>
                        </div>
                        <div className="my-12">
                            <div className="flex items-start justify-center gap-3">

                                <p className={`f-bold text-xl cursor-pointer ${secState === 'packages' ? 'opacity-100' : 'opacity-50'}`} onClick={() => setSecState('packages')}>{t('packages')}</p>

                                <div className="w-[1px] h-9 rotate-180 bg-gray-600"></div>

                                <p className={`f-bold text-xl cursor-pointer ${secState === 'courses' ? 'opacity-100' : 'opacity-50'}`} onClick={() => setSecState('courses')}>{t('courses')}</p>

                            </div>
                            <div className="my-8">
                                {
                                    secState === 'packages' ? (userPackages?.map(pack => (
                                        <SubscriptionsBox key={pack?.id} item={pack} WhereLink={`packages`} />
                                    ))) : (userCourses?.map((course) => (
                                        <SubscriptionsBox key={course?.id} item={course} WhereLink={`courses`} />
                                    )))
                                }
                                {
                                    (userCourses?.length <= 0 || userCourses?.length <= 0) && <EmptySubscriptions />
                                }
                            </div>
                        </div>
                    </section>
                </section>
            </main>
        </>
    )
}
export default Profile