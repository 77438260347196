import { Link } from "react-router-dom"
import RatingComponent from "./RatingComponent"
import AddToFavourite from "../../hooks/CustomHooks/SubscribeHooks/AddToFavourite";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CourseBox = ({ course, className }) => {
    const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
    const [favDone, setFavDone] = useState(false);
    const [favLoading, setFavLoading] = useState(false);
    const [favErr, setFavErr] = useState(null);
    const { t } = useTranslation();

    const favFunc = async (id, whereAddFavourite) => {
        setFavLoading(true);
        const { done, err } = await AddToFavourite(id, whereAddFavourite)
        setFavDone(done);
        setFavErr(err)
        setFavLoading(false);
    };

    return (
        <div>
            <div className="p-3 border-2 transition-all duration-300 ease-in-out hover:border-red-600 hover:rounded-3xl" style={{ overflow: 'hidden' }}>
                <div className="relative">
                    <Link to={`/courses/${course.id}`}>
                        <div>
                            <img
                                src={`${IMG_URL}${course?.image}`}
                                alt={`course-${course.id}`}
                                className={`rounded-lg ${className}`}
                            />
                        </div>
                    </Link>
                    <div className="absolute left-4 top-4 cursor-pointer">
                        {favErr ? (
                            <div className="bg-white w-11 h-11 flex items-center justify-center rounded-lg">
                                <p className="text-xl m-0">⛔</p>
                            </div>
                        ) : favLoading ? (
                            <div className="bg-white w-11 h-11 flex items-center justify-center rounded-lg">
                                <div className="animate-spin rounded-full border-t-4 border-b-4 border-gray-400 h-7 w-7 z-50"></div>
                            </div>
                        ) : (
                            <>
                                {course?.is_favourite ? (
                                    <img
                                        src="/icons/favourite.svg"
                                        alt="favourite-icon"
                                        title={t('favItem')}
                                        className="w-full h-full"
                                    />
                                ) : favDone ? (
                                    <img
                                        src="/icons/favourite.svg"
                                        alt="favourite-icon"
                                        title={t('favAdded')}
                                    />
                                ) : (
                                    <img
                                        src="/icons/non-favourite.svg"
                                        alt="non-favourite-icon"
                                        className="z-0"
                                        title={t('addFav')}
                                        onClick={() => {
                                            if (!favLoading) {
                                                favFunc(course?.id, 'courses');
                                            }
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
                <Link to={`/courses/${course.id}`}>
                    <div className="my-4">
                        <h1 className="f-semi-bold mob:text-base">{course.translations[0].name}</h1>
                        <p className="text-gray-500 f-rg my-3 mob:text-sm" dangerouslySetInnerHTML={{ __html: course.translations[0].desc }} />
                    </div>
                </Link>
                <div className="flex items-baseline gap-1">
                    <RatingComponent rate={course.rate} />
                    <p className="f-semi-bold me-2">
                        ( {course.rate} {t('rate')} )
                    </p>
                </div>
                <Link to={`/courses/${course.id}`}>
                    <div className="flex justify-between mt-4 mob:flex-col mob:gap-4">
                        <button className="animate-btn w-fit">
                            <i className="fas fa-play"></i>
                            {t('watchNow')}
                        </button>
                        <div>
                            <p className="f-bold">{course.price ? `${course.price} ${t('riyal')}` : t('free')}</p>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}
export default CourseBox