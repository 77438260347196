import axios from "axios";
const LOGIN_API = process.env.REACT_APP_LOGIN_API_URL

const LoginUser = async (email, password) => {
    try {
        const res = await axios.post(
            LOGIN_API,
            { email, password },
            { headers: { "Content-Type": "application/json" } }
        );
        return { data: res.data, done: true, err: null }
    } catch (err) {
        return { data: null, done: false, err: err?.response?.data?.data || 'False data enterned , please try again' }
    }
};
export default LoginUser