import { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import PackageBox from '../PackagesComponents/PackageBox';
import 'swiper/css';
import { useSelector } from "react-redux";
import { HomeErr, HomeLoading, PackagesSelector } from "../../store/slices/HomeSlice";
import LoadingComponent from "../../UI/CustomUI/LoadingComponent";
import { useTranslation } from "react-i18next";

const PackagesSwiper = ({ boxClassName, slidesPerView, seeFavourite }) => {
    const { t } = useTranslation();
    const swiperRef = useRef(null);
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const packages = useSelector(PackagesSelector);
    const loading = useSelector(HomeLoading);
    const error = useSelector(HomeErr);

    useEffect(() => {
        if (swiperRef.current) {
            const swiper = swiperRef.current.swiper;

            const updateButtonState = () => {
                setIsBeginning(swiper.isBeginning);
                setIsEnd(swiper.isEnd);
            };

            swiper.on('slideChange', updateButtonState);
            updateButtonState();

            // Clean up the event listener on component unmount
            return () => {
                swiper.off('slideChange', updateButtonState);
            };
        }
    }, []);

    if (packages.length === 0 || error) {
        return <div className="f-bold text-red-500 text-2xl pt-20">
            {t('soryyNoData')}
        </div>;
    }

    if (!packages && loading) {
        return <LoadingComponent parentClass={`grid gap-3 p-4 grid-cols-${slidesPerView}`} childClass={`h-[25rem]`} childrenNumber={slidesPerView} />;
    }

    return (
        <section className="flex items-center gap-3">
            <button
                onClick={() => {
                    if (swiperRef.current) {
                        swiperRef.current.swiper.slidePrev();
                    }
                }}
                className={`swiper-btn ${isBeginning ? 'opacity-40 cursor-not-allowed' : ''}`}
                disabled={isBeginning}
            >
                {
                    localStorage.getItem('i18nextLng') === 'en' ? <img src="/icons/left-arrow.svg" alt="Left Arrow" /> : <img src="/icons/right-arrow.svg" alt="Right Arrow" />
                }
            </button>
            <Swiper
                ref={swiperRef}  // ref
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                pagination={{ clickable: false }}
                slidesPerView={slidesPerView}
                scrollbar={{ draggable: true }}
                className="w-full"
                spaceBetween={30} // Add space between slides (in pixels)
            >
                {packages?.map((pack) => (
                    <SwiperSlide key={pack?.id}>
                        <PackageBox pack={pack} boxClassName={boxClassName} slidesPerView={slidesPerView} seeFavourite={seeFavourite} />
                    </SwiperSlide>
                ))}
            </Swiper>
            <button
                onClick={() => {
                    if (swiperRef.current) {
                        swiperRef.current.swiper.slideNext();
                    }
                }}
                className={`swiper-btn ${isEnd ? 'opacity-40 cursor-not-allowed' : ''}`}
                disabled={isEnd}
            >
                {
                    localStorage.getItem('i18nextLng') === 'en' ? <img src="/icons/right-arrow.svg" alt="Right Arrow" /> : <img src="/icons/left-arrow.svg" alt="Left Arrow" />
                }
            </button>
        </section>
    );
};

export default PackagesSwiper;
