import { useTranslation } from "react-i18next";

const TrueValidate = ({ theSeenPackage }) => {
    const { t } = useTranslation();
    const details = [
        t('payDemo'), t('payDemo'), t('payDemo'), t('payDemo'), t('payDemo'), t('payDemo'), t('payDemo'), t('payDemo'), t('payDemo'), t('payDemo'), t('payDemo'), t('payDemo')
    ];
    return (
        <section className="bg-[#F5F5F5] p-4 px-14 rounded-lg w-[49rem] tab:w-[32rem] mob:w-full" >
            <div className="grid  justify-items-center">
                <div><img src="/icons/check-success.svg" alt="success" /></div>
                <div>
                    <p className="f-bold text-xl">{t('payDone')}</p>
                </div>
            </div>
            <hr />
            <div className="flex items-center justify-between">
                <div className="flex flex-col items-center w-fit my-3 ">
                    <h1 className="f-ex-bold text-lg">{t('details')}</h1>
                    <div><img src="/imgs/curve.png" alt="curve" className="w-32" /></div>
                </div>
                <div>
                    <img src="/icons/check-success.svg" alt="success" className="w-7" />
                </div>
            </div>
            <div>
                <ul>
                    {
                        details?.map((detail, index) => (
                            <li key={index} className="f-semi-bold my-2 mob:text-base">
                                {'\u25A0'} {detail}
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className="my-12">
                <div>
                    <h1 className="f-bold text-red-600 text-3xl">
                        {theSeenPackage?.price} {t('riyal')}
                    </h1>
                    <h2 className="f-bold text-lg">
                        {theSeenPackage?.translations ? theSeenPackage?.translations[0].name : t('platinum')}
                    </h2>
                </div>
            </div>
        </section>
    )
}
export default TrueValidate