import { useParams } from "react-router-dom";
import NavComponent from "../../../UI/CustomUI/NavComponent";
import FormValidation from "./FormValidation";
import TrueValidate from "./TrueValidate";
import FalseValidate from "./FalseValidate";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../../UI/CustomUI/LoadingComponent";
import { useEffect } from "react";
import { PackageDT, PackageDTLoading, ShowPackageDetails } from "../../../store/slices/PackageDTSlice";
import { useTranslation } from "react-i18next";

const PackagePaying = () => {
    const { t } = useTranslation();
    const param = useParams();
    const dispatch = useDispatch();
    const packages = useSelector(PackageDT);
    const loading = useSelector(PackageDTLoading);
    const theSeenPackage = packages.find(pack => parseInt(pack.id) === parseInt(param.packPriceId));

    useEffect(() => {
        dispatch(ShowPackageDetails(param.packId))
    }, [dispatch, param.packId]);

    if (!theSeenPackage && !loading) {
        return <div className="text-2xl text-center m-auto py-11">
            <span className="font-bold">404 | ERROR</span>
            <p className="my-5 f-bold">{t('sorryNoDT')}</p>
        </div>
    };

    // IF LOADING 
    if (!packages || loading) return (<LoadingComponent parentClass={`grid p-5`} childClass={`h-[30rem] rounded-lg`} childrenNumber={1} />);
    return (
        <>
            <NavComponent firstURL={`/packages`} firstName={t('packages')} secName={t('pay')} />
            <main className="p-6 flex justify-center items-center mob:p-2" dir="ltr">
                <FormValidation theSeenPackage={theSeenPackage} />
            </main>
            <main className="p-6 flex justify-center items-center mob:p-2">
                <FalseValidate />
            </main>
            <main className="p-6 flex justify-center items-center mob:p-2">
                <TrueValidate theSeenPackage={theSeenPackage} />
            </main>
        </>
    )
}
export default PackagePaying