import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { ReturnedDTMonths, ReturnedDTErr, ReturnedDTLoading, FetchCourseOrPackageDTS } from "../../../store/slices/SubscriptionSlices/CourseOrPackageDT";

import { MonthDT, MonthDTErr, GetWeeksInMonth, MonthDTLoading } from "../../../store/slices/SubscriptionSlices/MonthDTSlice";
import LoadingComponent from "../../../UI/CustomUI/LoadingComponent";
import { useTranslation } from "react-i18next";

const AsideSubscriptionContent = ({ whoseItem, closeSideBarOnNavigate }) => {
    const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
    const [openIndex, setOpenIndex] = useState(null);
    const params = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // FOR COURSE OR PACKAGE MONTHS DATA
    const ReturnedMonths = useSelector(ReturnedDTMonths);
    const RetunedMonthsErr = useSelector(ReturnedDTErr);
    const ReturnedMonthsLoading = useSelector(ReturnedDTLoading);

    // FOR COURSE WEEKS OF MONTH DATA
    const WeeksOfMonth = useSelector(MonthDT);
    const ErrorInWeeks = useSelector(MonthDTErr);
    const LoadingOfWeeks = useSelector(MonthDTLoading);

    useEffect(() => {
        dispatch(FetchCourseOrPackageDTS({
            id: params.subscriptionId,
            whatItem: params.whatItem
        }))
    }, [dispatch, params.subscriptionId, params.whatItem]);

    const toggleMenu = (id) => {
        const newOpenIndex = openIndex === id ? null : id;
        setOpenIndex(newOpenIndex);

        if (newOpenIndex !== null) { // عشان نعملو FETCH لما تفتح بس
            dispatch(GetWeeksInMonth({
                id: id,
                whatItem: params.whatItem
            }));
        }
    };


    return (
        <>
            <div className={`${params.month ? 'hidden' : 'block'}`}>
                <div>
                    <img
                        src={`${IMG_URL}${whoseItem?.image}`}
                        alt={`${whoseItem?.months}-months`}
                        className="rounded border"
                    />
                </div>
                <p className="f-bold my-2 text-sm">
                    {whoseItem?.translations?.[0]?.name || t('courseName')}
                </p>
                <p className="f-rg my-2 text-xs text-gray-400">
                    {t('subscribeDate')}: {whoseItem?.translations?.[0]?.created_at?.split('T')[0] || t('subscribeDate')}
                </p>
            </div>
            <hr className={`${params.month ? 'hidden' : 'block'}`} />
            <div className="w-full max-w-sm mx-auto mt-3">
                {
                    ReturnedMonths.map((monthBtn) => (
                        <div key={monthBtn?.id} className="mb-4">
                            <div
                                className="flex items-start justify-between gap-4 cursor-pointer"
                                onClick={() => {
                                    toggleMenu(monthBtn?.id)
                                }}
                            >
                                <p className="f-bold text-sm w-56">
                                    <span className="f-bold text-red-500">
                                        {t("month")} ({monthBtn?.id}) :
                                    </span>
                                    {t('packDemo')}
                                </p>
                                <i className="fa-solid fa-chevron-down"></i>
                            </div>
                            <div
                                className={`mt-2 overflow-hidden transition-[max-height,padding,opacity] duration-500 ease-in-out ${openIndex === monthBtn?.id ? 'max-h-96 opacity-100 py-4' : 'max-h-0 opacity-0 py-0'
                                    }`}
                            >
                                <ul className="bg-gray-200 p-3 rounded">
                                    {WeeksOfMonth?.map((week) => {
                                        return (
                                            <React.Fragment key={week?.id}>
                                                <li className="border-b f-rg">
                                                    <Link
                                                        to={`/subscriptions/${params.whatItem}/${whoseItem?.id}/month-${monthBtn?.id}/week-${week?.id}`}
                                                        onClick={closeSideBarOnNavigate}
                                                    >
                                                        {week?.id} {t('week')}
                                                    </Link>
                                                </li>
                                                <hr />
                                            </React.Fragment>
                                        )
                                    })}
                                    {
                                        (WeeksOfMonth.length <= 0 || ErrorInWeeks) && <div className="f-semi-bold text-red-500 text-base">{t("sorryNoDT")} - ( {monthBtn?.id} )</div>
                                    }
                                    {
                                        (LoadingOfWeeks) && <LoadingComponent parentClass={`grid gap-2 py-2 gap-2 w-full`} childClass={`h-[2rem] rounded-lg w-full`} childrenNumber={4} />
                                    }
                                </ul>
                            </div>
                        </div>
                    ))
                }
                {
                    (ReturnedMonths.length <= 0 && RetunedMonthsErr) && <div className="f-bold text-red-500 text-xl w-[15rem]">
                        عفوا , لا يوجد شهور لهذا التمرين
                    </div>
                }
                {
                    (ReturnedMonthsLoading && !RetunedMonthsErr) && <LoadingComponent parentClass={`grid gap-2 w-full`} childClass={`h-[5rem] w-full rounded-lg`} childrenNumber={6} />
                }
            </div>
        </>
    );
};

export default AsideSubscriptionContent;
