import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserIn } from "../../store/slices/ProfileSlice";

const NavBar = () => {
  const [seeMenu, setSeeMenu] = useState(false);
  const [seeProfileDT, setSeeProfileDT] = useState(false);
  const { t, i18n } = useTranslation();
  const IfUserLoggedIn = useSelector(UserIn);

  const toggleSeeMenu = () => {
    setSeeMenu((prev) => !prev);
  };

  const closeMenuOnNavigate = () => {
    setSeeMenu(false);
  };

  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
    window.location.reload();
  };

  useEffect(() => {
    if (IfUserLoggedIn) {
      setSeeProfileDT(true)
    } else {
      setSeeProfileDT(false)
    }
  }, [IfUserLoggedIn]);

  return (
    <header className="py-6 px-4 shadow-sm relative overflow-hidden">
      {seeMenu && (
        <div
          className="fixed inset-0 bg-[#000000db] z-20"
          onClick={toggleSeeMenu} // CLICKING ON IT TO REMOVE MENU
        />
      )}
      <nav className={`flex items-center justify-between gap-8`}>
        <div className="w-64 mx-5 tab:mx-0 tab:w-auto">
          <Link to={`/`}>
            <h1 className="f-ex-bold flex items-center text-2xl" dir={`${localStorage.getItem('i18nextLng') === 'en' ? 'rtl' : 'ltr'}`}>
              <div className="text-nowrap">
                <span className="text-red-500">DULIJAN </span>
                ACADEMY
              </div>
              <span>
                <img src="/icons/more-vertical.svg" alt="vertical" className="w-7" />
              </span>
            </h1>
          </Link>
        </div>
        <aside
          className={`block relative tab:h-dvh tab:fixed bg-white top-0 z-30 w-3/4 tab:py-8 mob:w-full transition-all duration-300 ${seeMenu ? "tab:left-0" : "tab:-left-[100rem]"
            } tab:overflow-y-scroll`}
        >
          <ul className="flex items-center gap-10 tab:flex-col p-0">
            <li>
              <NavLink to={`/`} className={`text-nowrap relative text-xl link ${localStorage.getItem('i18nextLng') === 'en' ? 'font-bold' : 'f-bold '}`}
                onClick={closeMenuOnNavigate}>
                {t('home')}
              </NavLink>
            </li>

            {
              seeProfileDT && <li>
                <NavLink to={`/subscriptions`} className={`text-nowrap relative text-xl link ${localStorage.getItem('i18nextLng') === 'en' ? 'font-bold' : 'f-bold'}`} onClick={closeMenuOnNavigate}>
                  {t('subscriptions')}
                </NavLink>
              </li>
            }
            <li>
              <NavLink to={`/courses`} className={`text-nowrap relative text-xl link ${localStorage.getItem('i18nextLng') === 'en' ? 'font-bold' : 'f-bold '}`} onClick={closeMenuOnNavigate}>
                {t('courses')}
              </NavLink>
            </li>
            <li>
              <NavLink to={`/packages`} className={`text-nowrap relative text-xl link ${localStorage.getItem('i18nextLng') === 'en' ? 'font-bold' : 'f-bold '}`} onClick={closeMenuOnNavigate}>
                {t('packages')}
              </NavLink>
            </li>
            <li>
              <NavLink to={`/contact`} className={`text-nowrap relative text-xl link ${localStorage.getItem('i18nextLng') === 'en' ? 'font-bold' : 'f-bold '}`} onClick={closeMenuOnNavigate}>
                {t('contact')}
              </NavLink>
            </li>
            <li>
              <NavLink to={`/about`} className={`text-nowrap relative text-xl link ${localStorage.getItem('i18nextLng') === 'en' ? 'font-bold' : 'f-bold '}`} onClick={closeMenuOnNavigate}>
                {t('about')}
              </NavLink>
            </li>
            {
              seeProfileDT ? <li>
                <NavLink to={`/profile`} className={`text-xl text-nowrap relative link hidden tab:block ${localStorage.getItem('i18nextLng') === 'en' ? 'font-bold' : 'f-bold'}`} onClick={closeMenuOnNavigate}>
                  {t('profile')}
                </NavLink>
              </li>
                :
                <li>
                  <NavLink to={`/login`} className={`text-xl text-nowrap relative link hidden tab:block ${localStorage.getItem('i18nextLng') === 'en' ? 'font-bold' : 'f-bold'}`} onClick={closeMenuOnNavigate}>
                    {t('login')}
                  </NavLink>
                </li>
            }
            <li>
              <div className="f-bold gap-4 items-center hidden tab:flex">
                <button className={`${localStorage.getItem('i18nextLng') === 'ar' ? 'opacity-100' : 'opacity-50'}`}
                  onClick={() => changeLang('ar')}
                >{t('ar')}</button>
                <span className="bg-gray-300 w-[1px] h-6"></span>
                <button className={`${localStorage.getItem('i18nextLng') === 'en' ? 'opacity-100' : 'opacity-50'}`}
                  onClick={() => changeLang('en')}
                >{t('en')}</button>
              </div>
            </li>
          </ul>
          <span
            className="absolute top-4 right-7 cursor-pointer hidden tab:block"
            onClick={toggleSeeMenu}
          >
            <i className="fas fa-times text-2xl text-red-500"></i>
          </span>
        </aside>
        <div>
          <ul className="flex items-center gap-2 p-0 tab:hidden">
            {
              seeProfileDT ?
                <li>
                  <NavLink to={`/profile`}>
                    <span className="rounded-full border-black border-2 w-10 h-10 flex justify-center items-center">
                      <i className="fas fa-user-alt" style={{ color: "red" }}></i>
                    </span>
                  </NavLink>
                </li>
                :
                <li>
                  <NavLink to={`/login`} className={`text-xl text-nowrap relative link ${localStorage.getItem('i18nextLng') === 'en' ? 'font-bold' : 'f-bold'}`} onClick={closeMenuOnNavigate}>
                    {t('login')}
                  </NavLink>
                </li>
            }
            <li className="w-10 cursor-pointer">
              {
                localStorage.getItem('i18nextLng') === 'ar' ?
                  <img src="/icons/ar.svg" alt="country" className="rounded-full border-black border w-full" title={t('changeLangToEnglish')} onClick={() => changeLang('en')} /> :
                  <img src="/icons/en.svg" alt="country" className="rounded-full border-black border w-full" title={t('changeLangToArabic')} onClick={() => changeLang('ar')} />
              }
            </li>
          </ul>
        </div>
        <div className="hidden tab:block cursor-pointer" onClick={toggleSeeMenu}>
          <i className="fas fa-bars" style={{ fontSize: "2rem" }}></i>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
