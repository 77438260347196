import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

import Navbar from "./components/AppComponents/navbar.jsx";
import Home from "./views/homeView.jsx";
import About from "./views/aboutView.jsx";
import Contact from "./views/contactView.jsx";
import Login from "./views/loginView.jsx";
import Register from "./views/registerView.jsx";
import Footer from "./components/AppComponents/footerContent.jsx";
import Packages from "./views/packagesView.jsx";
import PackageDetails from "./components/PackagesComponents/PackageDetails.jsx";
import PackagePaying from "./components/PackagesComponents/Paying & Validation/PackagePaying.jsx";
import Courses from "./views/coursesView.jsx";
import CoursesTrainingPage from "./components/CoursesComponents/CoursesTrainingPage.jsx";
import Profile from "./views/profileView.jsx";
import EditProfile from "./components/ProfileComponets/EditProfile.jsx";
import Subscriptions from "./views/subscriptionsView.jsx";
import SubscriptionPageDetails from "./components/SubscriptionsComponents/SubscriptionPageDetails.jsx";
import SubscriptionsMW from "./components/SubscriptionsComponents/SubscriptionsMW.jsx";
import SubscriptionsMWDay from "./components/SubscriptionsComponents/SubscriptionsMWDay.jsx";
import { useDispatch } from "react-redux";
import { fetchHomeData } from "./store/slices/HomeSlice.jsx";
import { useTranslation } from "react-i18next";
import ForgetPass from "./views/ForgetPassView.jsx";
import Verification from "./components/ForgetPassComponents/Verification.jsx";
import NewPass from "./components/ForgetPassComponents/NewPass.jsx";

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation()
  const getTextDirection = () => {
    const lang = localStorage.getItem('i18nextLng') || 'ar';
    return lang === 'ar' ? 'rtl' : 'ltr';
  };

  useEffect(() => {
    dispatch(fetchHomeData());
  }, [dispatch])

  useEffect(() => {
    document.body.setAttribute('dir', getTextDirection())
  }, [i18n.language])

  return (
    <Router>
      <LocationWrapper />
      <Navbar />
      <main className="min-h-screen">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/courses/:courseId" element={<CoursesTrainingPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/packages" element={<Packages />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/subscriptions/:whatItem/:subscriptionId" element={<SubscriptionPageDetails />} />
          <Route path="/subscriptions/:whatItem/:subscriptionId/:month/:week" element={<SubscriptionsMW />} />
          <Route path="/subscriptions/:whatItem/:subscriptionId/:month/:week/:day" element={<SubscriptionsMWDay />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/forget-password" element={<ForgetPass />} />
          <Route path="/forget-password/verification" element={<Verification />} />
          <Route path="/forget-password/new-password" element={<NewPass />} />
          <Route path="/profile/settings" element={<EditProfile />} />
          <Route path="/packages/:packId" element={<PackageDetails />} />
          <Route path="/packages/:packId/:packPriceId/pay" element={<PackagePaying />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

// TO HANDLE DISPATCHING AND SCROLLING
const LocationWrapper = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, [pathname]);

  return null;
};

export default App;
