import { Link, useNavigate } from "react-router-dom";
import NavComponent from "../UI/CustomUI/NavComponent";
import { useEffect, useState } from "react";
import LoginUser from "../hooks/CustomHooks/EmailHooks/LoginUser";
import Cookies from "js-cookie"
import { SetToken } from "../store/slices/ProfileSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Login = () => {
    const [errMsg, setErrMsg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [userDT, setUserDT] = useState({
        email: '',
        password: '',
        rememberMe: false
    })
    const [loggedInMSG, setLoggedInMSG] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const AddChanges = (e) => {
        const { name, value, type, checked } = e.target;

        setUserDT({
            ...userDT,
            [name]: type === "checkbox" ? checked : value
        });
    }

    const loginFunc = async (e) => {
        e.preventDefault();
        setLoading(true)
        const { data, err } = await LoginUser(userDT.email, userDT.password);
        if (data !== null && data?.api_token) {
            dispatch(SetToken(data.api_token));
            Cookies.set("token", data.api_token);
            localStorage.setItem('userIn', true);
            await new Promise((resolve) => setTimeout(resolve, 100));
            navigate('/profile');
        } else {
            setErrMsg(err);
        }
        setLoading(false)
    };

    useEffect(() => {
        if (Cookies.get('token')) {
            setLoggedInMSG(true)
            const loggedInTimer = setTimeout(() => {
                navigate('/profile')
            }, 4000)
            return () => clearTimeout(loggedInTimer)
        }
    }, [navigate])

    return (
        <>
            {
                loggedInMSG ?
                    <div className="f-bold  h-dvh w-full text-3xl flex flex-col gap-4 justify-center items-center bg-white z-40">
                        <div className="text-center">
                            {t('alreadySigned')}
                        </div>
                        <div className="text-center">
                            {t('navigateYou')}
                        </div>
                    </div>
                    :
                    <>
                        <NavComponent firstName={t('login')} firstURL={`/login`} />
                        <main className="py-5 px-6 mob:px-2">
                            <div className="flex flex-col items-center w-fit mx-6 tab:mx-0">
                                <h1 className="f-ex-bold text-2xl ">{t('login')}</h1>
                                <div>
                                    <img src="/imgs/curve.png" alt="curve" className="w-auto" />
                                </div>
                            </div>
                            <section className="flex justify-center items-center my-5">
                                <section className="bg-[#F7F7FA] my-22 mx-auto p-4 shadow-md border rounded">
                                    <div className="flex flex-col items-center w-fit">
                                        <h1 className="f-ex-bold text-2xl ">{t('welcomeBack')}</h1>
                                        <div>
                                            <img src="/imgs/curve.png" alt="curve" className="w-auto" />
                                        </div>
                                    </div>
                                    <p className="f-semi-bold mx-3 my-5 text-gray-500 w-[23rem] mob:w-auto">
                                        {t('loginPara')}
                                    </p>
                                    {/* FORM */}
                                    <form method="POST" onSubmit={(e) => loginFunc(e)} className="pr-2">
                                        {/* EMAIL  */}
                                        <div className="px-2">
                                            <label htmlFor="email" className="f-bold text-xl">
                                                {t('email')}
                                            </label>
                                            <br />
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={userDT.email}
                                                onChange={AddChanges}
                                                placeholder={t('writeEmail')}
                                                className="my-2 border rounded-sm outline-none f-rg px-2 py-1 shadow-sm w-full"
                                                required
                                                aria-required={true}
                                            />
                                        </div>
                                        {/* PASSWORD */}
                                        <div className="px-2">
                                            <label htmlFor="password" className="f-bold text-xl">
                                                {t('pass')}
                                            </label>
                                            <br />
                                            <input
                                                type="password"
                                                id="password"
                                                name="password"
                                                value={userDT.password}
                                                onChange={AddChanges}
                                                placeholder={t('writePass')}
                                                className="my-2 border rounded-sm outline-none f-rg px-2 py-1 shadow-sm w-full"
                                                required
                                                aria-required={true}
                                            />
                                        </div>
                                        {/* CHECKBOX (REMEMBER-ME) & FORGET PASSWORD*/}
                                        <div className="flex items-center justify-between my-2">
                                            <div className="my-2 px-2">
                                                <input type="checkbox" name="remember-me" id="remember-me" checked={userDT.rememberMe}
                                                    onChange={AddChanges} />
                                                <label htmlFor="remember-me" className="f-bold text-sm mx-2">
                                                    {t('rememberMe')}
                                                </label>
                                            </div>
                                            <div className="text-red-600 f-bold text-sm underline">
                                                <Link to={`/forget-password`}>
                                                    {t('forgetPass')}
                                                </Link>
                                            </div>
                                        </div>
                                        <div>
                                            <button className="submit-btn" type="submit">
                                                {
                                                    loading ? (
                                                        <div className="flex justify-center">
                                                            <span className="mx-2 f-bold"
                                                            >{t("loginning")}</span>
                                                            <div className="animate-spin rounded-full border-t-4 border-b-4 border-white h-7 w-7 mr-2"></div>
                                                        </div>
                                                    ) : (
                                                        localStorage.getItem('i18nextLng') === 'en' ? <div>
                                                            <span className="mx-2 f-bold">{t('login')}</span>{" "}
                                                            <i className="fa-solid fa-arrow-right"></i>
                                                        </div> : <div>
                                                            <span className="mx-2 f-bold">{t('login')}</span>{" "}
                                                            <i className="fa-solid fa-arrow-left"></i>
                                                        </div>
                                                    )}
                                            </button>
                                        </div>
                                    </form>
                                    <div className="text-center mt-4">
                                        <span className="text-gray-500 f-semi-bold">
                                            {t('dontHaveEmail')}
                                        </span>
                                        <span
                                            className="text-red-500 underline"
                                        >
                                            <Link to={`/register`} className="f-bold mx-2">
                                                {t('createEmail')}
                                            </Link>
                                        </span>
                                    </div>
                                </section>
                            </section>
                            {errMsg && (
                                <p className="text-red-500 text-xl text-center">
                                    {errMsg}
                                </p>
                            )}
                        </main>
                    </>
            }

        </>
    );
};

export default Login;
