import LandingContent from '../components/HomeComponents/landingContent'
import PackagesContent from "../components/HomeComponents/packagesContent";
import TrainerReviews from "../components/HomeComponents/trainerReviews";
import { Link } from "react-router-dom";
import AboutContent from "../UI/CustomUI/aboutContent";
import { useSelector } from 'react-redux';
import { CoursesSelector, HomeErr, HomeLoading, ResultsSelector, ReviewsSelector } from '../store/slices/HomeSlice';
import LoadingComponent from '../UI/CustomUI/LoadingComponent';
import { useTranslation } from 'react-i18next';

const Home = () => {
    const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
    const { t } = useTranslation();

    const error = useSelector(HomeErr)
    const loading = useSelector(HomeLoading)
    const courses = useSelector(CoursesSelector)
    const results = useSelector(ResultsSelector)
    const reviews = useSelector(ReviewsSelector)
    //  LOADING
    if (loading) return <LoadingComponent parentClass={`grid grid-cols-2 gap-3 mob:grid-cols-1 w-full p-3`} childrenNumber={2} childClass={`h-[28rem] w-full rounded-lg`} />;

    // ERROR
    if (error && !loading) return <div className='f-bold text-2xl text-red-500 text-center pt-20'>
        {t("soryyNoData")}
        <p className='my-4'>{error}</p>
    </div>
    const companies = ['/imgs/NCSF.png', '/imgs/ACE.png', '/imgs/ACSM.png'];

    return (
        <div>
            <LandingContent />
            <PackagesContent courses={courses} />
            <main className="py-3 px-5 my-6">
                {/* ABOUT */}
                <section className="my-7">
                    <div className="flex flex-col items-center w-fit">
                        <h1 className="f-bold text-2xl ">{t("aboutUs")} </h1>
                        <div><img src="/imgs/curve.png" alt="curve" className="w-auto" /></div>
                    </div>
                    <AboutContent slot={
                        <Link to={`/packages`}>
                            <button className="animate-btn">
                                <i className="fas fa-play"></i>
                                {t("aboutPackages")}
                            </button>
                        </Link>
                    } />
                </section>
                {/* WHAT WE DO */}
                <section className="my-5">
                    <div className="bg-[#2A2A2B] rounded-t-[5rem] text-white text-center p-8">
                        <div>
                            <h1 className="f-bold text-2xl">
                                {t('helpPpl.0')} <span className="text-red-600 f-bold">{t('helpPpl.1')}</span> {t('helpPpl.2')}
                            </h1>
                        </div>
                        <div dir="ltr" className="flex justify-center gap-8 mt-8 items-center">
                            <div>
                                <p className="text-4xl my-0 font-bold">45K <span className="text-red-600">+</span></p>
                                <p className="f-bold">{t('nowSubsc')}</p>
                            </div>
                            <hr className="bg-white rotate-180 h-20 w-px" />
                            <div>
                                <p className=" text-4xl my-0 font-bold">328 <span className="text-red-600">+</span></p>
                                <p className="f-bold">{t('satisSubsc')}</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* COMPANIES */}
                <section className="my-5">
                    <div className="flex flex-col items-center w-fit my-5">
                        <h1 className="f-bold text-2xl"> {t('approved')}</h1>
                        <div><img src="/imgs/curve.png" alt="curve" className="w-auto" /></div>
                    </div>
                    <div className="grid grid-cols-3 gap-2.5">
                        {
                            companies?.map((company, index) => (
                                <div key={index}>
                                    <img src={company} alt={company} className="my-2 w-full" />
                                </div>
                            ))
                        }
                    </div>
                </section>
            </main>
            {/* REVIEWS */}
            <main className="mainBg py-6">
                <div className="flex flex-col items-center w-fit mt-3 px-4">
                    <h1 className="f-bold text-2xl ">{t('reviews')}</h1>
                    <div><img src="/imgs/curve.png" alt="curve" className="w-auto" /></div>
                </div>
                <section className="py-14 mob:py-5">
                    <TrainerReviews reviews={reviews} />
                    {
                        (error || reviews?.length <= 0) && <div className='f-bold text-center py-20 text-xl text-red-500'>
                            {t('noReviews')}
                        </div>
                    }
                </section>
            </main>
            {/* RESULTS */}
            <main className="p-8">
                <div className="flex flex-col items-center w-fit my-5">
                    <h1 className="f-bold text-2xl ">{t('results')}</h1>
                    <div><img src="/imgs/curve.png" alt="curve" className="w-auto" /></div>
                </div>
                {/* CSS SCROLLBAR (NO NEED SWIPER) */}
                <section className="grid grid-cols-3 gap-3 justify-items-center mob:flex mob:scroll-smooth mob:justify-start mob:snap-x mob:overflow-x-auto hide-scrollbar">
                    {
                        results?.map((res) => (
                            <div key={res?.id}>
                                <div className="bg-[#e4e4e4] w-[20.6875rem] h-[17.75rem]">
                                    <img
                                        src={`${IMG_URL}${res?.image}`}
                                        alt={`trainer-${res?.id}`}
                                        className="max-w-[20.6875rem] max-h-[17.75rem] object-cover"
                                    />
                                </div>
                            </div>
                        ))
                    }
                    {
                        !results && loading && <LoadingComponent parentClass={`grid grid-cols-3 gap-3 justify-items-center mob:flex mob:scroll-smooth mob:justify-start mob:snap-x mob:overflow-x-auto hide-scrollbar`} childClass={`h-[20rem]`} childrenNumber={4} />
                    }
                    {
                        (error || results?.length <= 0) && <div className='f-bold text-center py-20 text-xl text-red-500'>
                            {t('noResults')}
                        </div>
                    }
                </section>
            </main>
        </div>
    );
}
export default Home; 