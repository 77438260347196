import { useState } from "react";
import { Link } from "react-router-dom"
import AddToFavourite from "../../hooks/CustomHooks/SubscribeHooks/AddToFavourite";
import { useTranslation } from "react-i18next";

const PackageBox = ({ pack, boxClassName, seeFavourite, slidesPerView }) => {
    const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
    const [favDone, setFavDone] = useState(false);
    const [favLoading, setFavLoading] = useState(false);
    const [favErr, setFavErr] = useState(null)
    const { t } = useTranslation();

    const favFunc = async (id, whereAddFavourite) => {
        setFavLoading(true)
        const { done, err } = await AddToFavourite(id, whereAddFavourite)
        setFavDone(done)
        setFavErr(err)
        setFavLoading(false)
    };
    return (
        <div>
            <div className={`flex gap-4 mx-2`}>
                {/* لو عايز البوكس Flex او Grid */}
                <div className={`${boxClassName} swiper-box cursor-pointer`}>
                    <div className="relative">
                        <Link to={`/packages/${pack?.id}`}>
                            <div>
                                <img
                                    src={`${IMG_URL}${pack?.image}`}
                                    alt={`${pack?.months}-month`}
                                    className="rounded-lg w-full h-full object-fill"
                                />
                            </div>
                        </Link>
                        <div className="absolute left-4 top-4 ">
                            {seeFavourite && (
                                <>
                                    {favErr ? (
                                        <div className="bg-white w-11 h-11 flex items-center justify-center rounded-lg">
                                            <p className="text-xl m-0">⛔</p>
                                        </div>
                                    ) : favLoading ? (
                                        <div className="bg-white w-11 h-11 flex items-center justify-center rounded-lg">
                                            <div className="animate-spin rounded-full border-t-4 border-b-4 border-gray-400 h-7 w-7 z-50"></div>
                                        </div>
                                    ) : (
                                        <>
                                            {pack?.is_favourite ? (
                                                <img
                                                    src="/icons/favourite.svg"
                                                    alt="favourite-icon"
                                                    title={t('favItem')}
                                                    className="w-full h-full"
                                                />
                                            ) : favDone ? (
                                                <img
                                                    src="/icons/favourite.svg"
                                                    alt="favourite-icon"
                                                    title={t('favAdded')}
                                                />
                                            ) : (
                                                <img
                                                    src="/icons/non-favourite.svg"
                                                    alt="non-favourite-icon"
                                                    className="z-0"
                                                    title={t('addFav')}
                                                    onClick={() => {
                                                        if (!favLoading) {
                                                            favFunc(pack?.id, 'packages');
                                                        }
                                                    }}
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>

                    </div>
                    <Link to={`/packages/${pack?.id}`}>
                        <div className="my-2">
                            <div>
                                <span className="text-2xl f-bold text-red-600">
                                    {pack?.price_after} {t("riyal")}
                                </span>
                                <del className="text-مل f-rg text-gray-500 mx-2">
                                    {pack?.price_before} {t("riyal")}
                                </del>
                            </div>
                            <div>
                                <p
                                    className="f-bold text-2xl my-3 w-80"
                                    dangerouslySetInnerHTML={{ __html: pack?.translations[0].desc }}
                                />
                            </div>
                            <div>
                                <i className="fa-solid fa-star" style={{ color: '#FFD43B' }}></i>
                                <span className="f-semi-bold text-gray-500 mx-2">
                                    ( {pack?.rate} {t("rate")} )
                                </span>
                            </div>
                            <hr className="my-2" />
                            <div dir="ltr">
                                <button className="f-semi-bold">
                                    {localStorage.getItem('i18nextLng') === 'en' ? `${t("viewDetails")} \u2192` : `\u2190 ${t("viewDetails")}`}
                                </button>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="me-2 swiper-line">
                    {slidesPerView < 3 ? (
                        <img src="/icons/short-line.png" alt="line" className="w-12 tab:hidden" />
                    ) : (
                        <img src="/icons/long-line.png" alt="line" className="h-full w-8 tab:hidden" />
                    )}
                </div>
            </div>
        </div>
    )
}
export default PackageBox