const SubscriptionVideoBox = ({ item, index, totalLength }) => {
    const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
    return (
        <div className="flex items-start gap-4 border p-3 rounded-xl my-3 mob:flex-col">
            <div><img src={`${IMG_URL}${item?.image}`} alt={`item-${index}`} className="w-[18.3rem] h-[10.25rem] object-cover tab:w-full tab:h-full" /></div>
            <div className="my-3">
                <p className="f-semi-bold text-gray-400 text-xl mob:text-base" dangerouslySetInnerHTML={{ __html: item.translations[0].desc }} />
                <div style={{ textDecoration: 'underline', textDecorationColor: 'red' }} className="f-bold">
                    ( {index} ) : <span className="text-gray-400 f-bold">( {totalLength} )</span>
                </div>
            </div>
        </div>
    )
}
export default SubscriptionVideoBox