import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie"

const PROFILE_URL = process.env.REACT_APP_PROFILE_DATA_API_URL + `?locale=${localStorage.getItem('i18nextLng')}`


export const profileDataFetching = createAsyncThunk(
    'profileSlice/profileDataFetching',
    async (_, { rejectWithValue, getState }) => {

        const token = getState().profile.token || Cookies.get('token')
        try {
            const response = await axios.get(PROFILE_URL, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            return response.data.data
        } catch (err) {
            return rejectWithValue(err.response?.data?.data || 'Something went wrong');
        }
    }
)

const profileSlice = createSlice({
    name: 'profileSlice',
    initialState: {
        courses: null,
        packages: null,
        profile: null,
        loading: false,
        error: null,
        token: null,
        userIn: false
    },
    extraReducers: (builder) => {
        builder
            .addCase(profileDataFetching.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(profileDataFetching.fulfilled, (state, action) => {
                const { courses, packages, profile } = action.payload;
                state.userIn = true;
                state.courses = courses;
                state.packages = packages;
                state.profile = profile;
                state.loading = false;
            })
            .addCase(profileDataFetching.rejected, (state, action) => {
                state.userIn = false;
                state.loading = false;
                state.error = action.payload;
            })
    },
    reducers: {
        SetToken: (state, action) => {
            state.token = action.payload
        },
        RemoveToken: (state) => {
            state.userIn = false;
            state.token = null;
            Cookies.remove('token');
        }
    }
})
export const UserCoursesSelector = (state) => state.profile.courses;
export const UserPackagesSelector = (state) => state.profile.packages;
export const UserProfileSelector = (state) => state.profile.profile;
export const UserLoading = (state) => state.profile.loading;
export const UserErr = (state) => state.profile.error;
export const UserToken = (state) => state.profile.token;
export const UserIn = (state) => state.profile.userIn

export const { SetToken, RemoveToken } = profileSlice.actions;
export default profileSlice.reducer