import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
const EmptySubscriptions = () => {
    const { t } = useTranslation();
    return (
        <div className="border-2 shadow-sm py-5 grid gap-4 rounded-lg justify-items-center">
            <div className="grid justify-items-center bg-[#E8E8E8] p-3 rounded-xl">
                <div><img src="/imgs/no-subscriptions.svg" alt="no-subscriptions" /></div>
                <p className="f-bold text-center">{t('noSubsc')}</p>
            </div>
            <div className="text-red-600">
                <Link to={`/packages`} className="f-bold" >
                    {t('subscribeNow')}
                </Link>
            </div>
        </div>
    )
}
export default EmptySubscriptions