import { useParams } from "react-router-dom";
import NavComponent from "../../UI/CustomUI/NavComponent";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../UI/CustomUI/LoadingComponent";
import { useEffect } from "react";
import { PackageDT, PackageDTErr, PackageDTLoading, ShowPackageDetails } from "../../store/slices/PackageDTSlice";
import { useTranslation } from "react-i18next";

const PackageDetails = () => {
    const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
    const dispatch = useDispatch();
    const param = useParams();
    const NowPackageDTS = useSelector(PackageDT);
    const NowPackErr = useSelector(PackageDTErr);
    const NowPackageLoading = useSelector(PackageDTLoading)
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(ShowPackageDetails(param.packId))
    }, [dispatch, param.packId])

    // FOR ANY ERROR
    if (NowPackErr) {
        return <div className="text-2xl text-center m-auto py-11">
            <span className="font-bold">404 | ERROR</span>
            <p className="my-5 f-bold">{t("notAvailablePK")}</p>
        </div>
    }
    // IF LOADING 
    if (NowPackageLoading) return <LoadingComponent parentClass={`grid grid-cols-3 gap-3 justify-items-center mob:flex mob:scroll-smooth mob:justify-start mob:snap-x mob:overflow-x-auto hide-scrollbar`} childClass={`h-[20rem]`} childrenNumber={3} />
    return (
        <>
            <NavComponent firstURL={`/packages`} firstName={t("packages")} secName={t("packageTrain")} />
            <main className="mb-14">
                <section className="flex justify-center items-start gap-3 my-10 py-4 px-40 tab:px-6 mob:px-2 mob:gap-1 mob:scroll-smooth mob:justify-start mob:snap-x mob:overflow-x-auto hide-scrollbar">
                    {
                        NowPackageDTS.map((packDT, packIndex) => (
                            <div key={packDT.id} className={`grid p-3 border rounded-lg w-[19.5rem] h-[32.875rem] tab:w-full mob:w-[30rem] ${(packIndex + 1) % 2 === 0 ? "bg-[#AC0B2E] text-white" : null}`}>
                                <div>
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <h1 className={`f-black  text-xl ${(packIndex + 1) % 2 === 0 ? 'text-[#FF9F9F]' : 'text-red-600'}`}>{packDT.price} {t('riyal')}</h1>
                                            <h3 className="f-bold text-lg">{packDT?.translations ? packDT?.translations[0]?.name : t("service")}</h3>
                                        </div>
                                        <div>
                                            <img src={`${IMG_URL}${packDT.image}`} alt={packDT.title} title={packDT.title} className="block w-12 h-12 mob:hidden" />
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <ul className="p-0">
                                            {
                                                packDT.details?.map((detail, index) => (
                                                    <li key={index} className="f-semi-bold my-2 mob:text-sm mob:w-[15rem]">{'\u25A0'} {detail}</li>
                                                ))
                                            }
                                            {
                                                !packDT?.details && <li dangerouslySetInnerHTML={{ __html: packDT?.translations ? packDT?.translations[0]?.desc : t("details") }} className="f-bold" />
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className=" self-end">
                                    <div>
                                        <i className="fa-solid fa-star" style={{ color: '#FFD43B' }}></i>
                                        <span className="f-semi-bold mx-1">
                                            ( {packDT.rate} {t("rate")} )
                                        </span>
                                    </div>
                                    <div className="my-3">
                                        <Link to={`/packages/${param.packId}/${packDT.id}/pay`}>
                                            <button className={`animate-btn ${(packIndex + 1) % 2 === 0 ? 'text-white' : null}`}>
                                                {t("buyNow")}
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </section>
                {
                    NowPackageDTS.length <= 0 && <div className="f-bold text-2xl text-center text-red-500">
                        {t("sorryNoDT")}
                    </div>
                }
                <hr className="mx-28 my-5 mob:mx-4" />
                <section className="mx-28 mob:mx-4">
                    <div className="flex flex-col items-center w-fit">
                        <h1 className="f-bold text-2xl ">{t("details")}</h1>
                        <div><img src="/imgs/curve.png" alt="curve" className="w-auto" /></div>
                    </div>
                    <div className="my-4">
                        <ul>
                            {
                                NowPackageDTS.details?.map((detail, detIndex) => (
                                    <li key={detIndex} className="f-bold my-3 text-xl mob:text-base">{'\u25A0'} {detail}</li>
                                ))
                            }
                            {
                                !NowPackageDTS?.details && <div className="f-bold text-2xl text-center py-10 text-red-500">
                                    {t("sorryNoDT")}
                                </div>
                            }
                        </ul>
                    </div>
                </section>
            </main>
        </>
    )
}
export default PackageDetails