import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { useRef } from "react";
import 'swiper/css';
import ReviewBox from "../../UI/CustomUI/reviewBox";
import IsTabletOrLarger from "../../hooks/UIHooks/isTabletOrLarger";


const TrainerReviews = ({ reviews }) => {
    const swiperRef = useRef(null)
    const isTabletOrLarger = IsTabletOrLarger('(min-width: 1025px)')

    return (
        <>
            <section className="flex items-center px-3 gap-4 my-2">
                <button onClick={() => {
                    if (swiperRef.current) {
                        const swiper = swiperRef.current.swiper;
                        swiper.slidePrev();
                    }
                }} className="swiper-btn">
                    {
                        localStorage.getItem('i18nextLng') === 'en' ? <img src="/icons/left-arrow.svg" alt="Left Arrow" /> : <img src="/icons/right-arrow.svg" alt="Right Arrow" />
                    }
                </button>
                <Swiper
                    ref={swiperRef}
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    pagination={{ clickable: false }}
                    slidesPerView={isTabletOrLarger ? 3 : 1}
                    scrollbar={{ draggable: true }}
                    className="w-full"
                    spaceBetween={10}
                >
                    {
                        reviews.map((review) => (
                            <SwiperSlide key={review.id}>
                                <ReviewBox review={review} />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                <button onClick={() => {
                    if (swiperRef.current) {
                        const swiper = swiperRef.current.swiper;
                        swiper.slideNext();
                    }
                }} className="swiper-btn">
                    {
                        localStorage.getItem('i18nextLng') === 'en' ? <img src="/icons/right-arrow.svg" alt="Right Arrow" /> : <img src="/icons/left-arrow.svg" alt="Left Arrow" />
                    }
                </button>
            </section>
        </>
    )
}
export default TrainerReviews