import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetPrice, PriceSelector } from "../../../store/slices/ChoosedDTSlice";
import { useLocation, useNavigate } from "react-router-dom";

export const PriceRadio = ({ arrTitle, arr }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [checkIfNum, setCheckIfNum] = useState(0);
    const dispatch = useDispatch();
    const GetPriceNum = (e) => {
        if (location.pathname !== '/courses') {
            navigate('/courses');
        }
        dispatch(GetPrice(e.target.value));
        setCheckIfNum(e.target.value)
    };
    const toSeeIfNull = useSelector(PriceSelector);

    useEffect(() => {
        if (toSeeIfNull === null) {
            setCheckIfNum(null)
        }
    }, [toSeeIfNull]);

    return (
        <div className="p-3 rounded-lg bg-[#F7F7F9] tab:w-full">
            <h1 className="f-bold text-xl">{arrTitle}</h1>
            <div>
                {
                    arr?.map((arrItem) => (
                        <div key={arrItem?.id} className="w-full">
                            <input
                                type="radio"
                                value={arrItem?.id}
                                name={arrTitle}
                                id={`${arrTitle}-${arrItem?.id}`}
                                onChange={GetPriceNum}
                                checked={checkIfNum === String(arrItem.id)}
                            />
                            <label
                                htmlFor={`${arrTitle}-${arrItem?.id}`}
                                className="f-rg mx-2 text-nowrap"
                            >
                                {
                                    arrItem?.name
                                }
                            </label>
                        </div>

                    ))
                }
            </div>
        </div>
    )
}