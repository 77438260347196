import { useState, useEffect } from 'react';

const IsTabletOrLarger = ({ query = "(min-width: 1025px)" }) => {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    useEffect(() => {
        const mediaQueryList = window.matchMedia(query);

        // Function to update the matches state
        const handleChange = (event) => {
            setMatches(event.matches);
        };

        // Set the initial value correctly
        setMatches(mediaQueryList.matches);

        // Listen for changes in the media query
        mediaQueryList.addEventListener('change', handleChange);

        // Clean up the listener on component unmount
        return () => {
            mediaQueryList.removeEventListener('change', handleChange);
        };
    }, [query]);

    return matches;
};

export default IsTabletOrLarger;
