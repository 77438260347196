import { useDispatch, useSelector } from "react-redux";
import NavComponent from "../UI/CustomUI/NavComponent";
import CourseBox from "../UI/CoursesUI/courseBox";
import { useEffect, useState } from "react";
import { CoursesSelector, HomeErr, HomeLoading } from "../store/slices/HomeSlice";
import LoadingComponent from "../UI/CustomUI/LoadingComponent";
import {
    CategoriesErr,
    CategoriesLoading,
    CategoriesSelector,
    CategorySelector,
    ChoosedData,
    ChoosedDTErr,
    ChoosedDTLoading,
    GetChoosedDT,
    GettingCategories,
    LangSelector,
    PriceSelector,
    RateSelector,
    ResetChoosedDT
} from "../store/slices/ChoosedDTSlice";
import { useTranslation } from "react-i18next";
import { CategoryRadio } from "../UI/CoursesUI/CourseSideBarBoxs/CategoryRadio";
import { LangRadio } from "../UI/CoursesUI/CourseSideBarBoxs/LangRadio";
import { PriceRadio } from "../UI/CoursesUI/CourseSideBarBoxs/PriceRadio";
import { RatingRadio } from "../UI/CoursesUI/CourseSideBarBoxs/RatingRadio";

const Courses = () => {
    const [seeSideBar, setSeeSideBar] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const coursesPerPG = 4;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const seeBar = () => {
        setSeeSideBar(prevBool => !prevBool);
    };

    const langs = [
        { id: 1, locale: 'all', name: t('allLngs') },
        { id: 2, locale: 'ar', name: t('ar') },
        { id: 3, locale: 'en', name: t('en') }];
    const prices = [{ id: 1, name: t('allCat') }, { id: 2, name: t('free') }, { id: 3, name: t('paid') }];
    const rates = [5, 4, 3, 2, 1];

    const courses = useSelector(CoursesSelector);
    const homeErr = useSelector(HomeErr);
    const homeLoading = useSelector(HomeLoading);

    const ChoosedDT = useSelector(ChoosedData);
    const ChoosedPrice = useSelector(PriceSelector);
    const ChoosedCategory = useSelector(CategorySelector);
    const ChoosedRate = useSelector(RateSelector);
    const ChoosedLang = useSelector(LangSelector);

    const LoadingOfChoosed = useSelector(ChoosedDTLoading);
    const ChoosedErr = useSelector(ChoosedDTErr);

    const categories = useSelector(CategoriesSelector);
    const catLoading = useSelector(CategoriesLoading);
    const catErr = useSelector(CategoriesErr);

    // UPDATE CHOOSED DT
    useEffect(() => {
        if (ChoosedCategory || ChoosedPrice || ChoosedRate || ChoosedLang) {
            dispatch(GetChoosedDT({
                category_id: ChoosedCategory,
                rate: ChoosedRate,
                price: ChoosedPrice,
                locale: ChoosedLang
            }));
        }
    }, [ChoosedCategory, ChoosedPrice, ChoosedRate, ChoosedLang]);

    // RENDER CATEGORIES
    useEffect(() => {
        dispatch(GettingCategories());
    }, []);

    // RENDER COURSES
    useEffect(() => {
        setTotalCourses(ChoosedDT.length > 0 ? ChoosedDT : courses);
    }, [ChoosedDT, courses]);

    const [totalCourses, setTotalCourses] = useState(ChoosedDT.length > 0 ? ChoosedDT : courses)
    const totalPages = Math.ceil(totalCourses.length / coursesPerPG);
    const displayedCourses = totalCourses.slice((currentPage - 1) * coursesPerPG, currentPage * coursesPerPG);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const ResetDT = () => {
        dispatch(ResetChoosedDT());
        setTotalCourses(courses);
        setCurrentPage(1);
    }

    if (homeErr) return (
        <div className="text-2xl text-center m-auto py-11">
            <span className="font-bold">404 | ERROR</span>
            <p className="my-5 f-bold">{t("soryyNoData")}</p>
        </div>
    );

    if (homeLoading) return (
        <LoadingComponent parentClass={`grid grid-cols-4 p-4 gap-4`} childClass={[
            'h-[10rem] rounded-xl col-span-4',
            `h-svh rounded-xl col-span-1`,
            `h-svh rounded-xl col-span-3`,]} childrenNumber={3} />
    );

    return (
        <>
            <NavComponent firstName={t('courses')} firstURL={`/courses`} />
            <main className="my-8 px-3">
                {seeSideBar && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 z-10" onClick={seeBar} />
                )}
                <div className="flex flex-col items-center w-fit mx-6 tab:mx-0 tab:items-start">
                    <h1 className="f-bold text-2xl mx-4">{t('courses')}</h1>
                    <div><img src="/imgs/curve.png" alt="curve" className="w-auto" /></div>
                </div>
                <main className="flex gap-12 mob:gap-5 items-start tab:mt-16 my-12">
                    <div className="absolute top-44 bg-[#F7F7F9] hover:bg-gray-200 transition-all duration-200  rounded-lg hidden tab:block">
                        <button className="f-rg py-1 px-4" onClick={seeBar}>
                            {t('menu')}
                        </button>
                    </div>
                    <aside className="grid gap-4 w-3/12 p-2 tab:hidden">
                        {
                            catLoading ? <LoadingComponent parentClass={`grid gap-4 w-full`}
                                childClass={'h-[8rem] rounded-lg w-full'} childrenNumber={4} />
                                :
                                <>
                                    <CategoryRadio arr={categories} arrTitle={t('categories')} />
                                    <LangRadio arr={langs} arrTitle={t('lang')} />
                                    <PriceRadio arr={prices} arrTitle={t('price')} />
                                    <RatingRadio arr={rates} arrTitle={t('theRate')} />
                                    <button className="bg-[#F7F7F9] px-4 py-1 f-rg rounded-lg text-nowrap hover:bg-gray-300 transition-all duration-200"
                                        onClick={ResetDT}>
                                        {t('reset')}
                                    </button>
                                </>
                        }
                        {
                            catErr && <div className="f-bold text-xl text-red-500">{t('soryyNoData')}</div>
                        }
                    </aside>
                    {/* RESPONSIVE */}
                    <aside className={`bg-white h-full absolute hidden tab:block mob:w-[84%] tab:w-2/4 px-4 top-0 z-20 transition-all duration-150 py-12
                    ${localStorage.getItem('i18nextLng') === 'en' ? (seeSideBar ? '-translate-x-5' : '-translate-x-[100rem]') : (seeSideBar ? 'translate-x-5' : 'translate-x-[100rem]')} `}>
                        <p className={`f-bold absolute top-2 cursor-pointer tab:block ${localStorage.getItem('i18nextLng') === 'en' ? 'right-5' : 'left-5'}`} onClick={seeBar}>
                            <i className="fas fa-times text-2xl text-red-500"></i>
                        </p>
                        <aside className="grid gap-4 w-full p-2">
                            {
                                catLoading ? <LoadingComponent parentClass={`grid gap-4 py-4 w-full`} childClass={['h-[8rem] rounded-lg w-full', 'h-[8rem] rounded-lg w-full', 'h-[8rem] rounded-lg w-full', 'h-[8rem] rounded-lg w-full',]} childrenNumber={4} />
                                    :
                                    <>
                                        <CategoryRadio arr={categories} arrTitle={t('categories')} />
                                        <LangRadio arr={langs} arrTitle={t('lang')} />
                                        <PriceRadio arr={prices} arrTitle={t('price')} />
                                        <RatingRadio arr={rates} arrTitle={t('theRate')} />
                                        <button className="bg-gray-200 px-4 py-1 f-rg rounded-lg text-nowrap hover:bg-gray-300 transition-all duration-200"
                                            onClick={() => {
                                                ResetDT();
                                                seeBar();
                                            }}>
                                            {t('reset')}
                                        </button>
                                    </>
                            }
                            {
                                catErr && <div className="f-bold text-xl text-red-500">{t('soryyNoData')}</div>
                            }
                        </aside>
                    </aside>

                    {
                        (ChoosedDT.length <= 0 && ChoosedPrice && ChoosedCategory && ChoosedRate) || ChoosedErr ? (
                            <div className="text-center f-bold text-2xl text-red-500 pt-10">{t('sorryNoDT')}</div>
                        ) : (
                            <main className="m-0 p-0">
                                {LoadingOfChoosed ? (
                                    <LoadingComponent parentClass={`grid grid-cols-2 w-[70vw] p-4 gap-6 mob:grid-cols-1`}
                                        childClass={`h-80 rounded-xl w-full`}
                                        childrenNumber={4} />
                                ) : (
                                    <>
                                        <div className="my-4">
                                            <select name="menu" id="menu" className="f-rg w-40 bg-[#F7F7F9] rounded-md px-2 outline-none" defaultValue="">
                                                <option value="" disabled>
                                                    {t('defOrder')}
                                                </option>
                                                <option value="1">{t('option')} 1</option>
                                                <option value="2">{t('option')} 2</option>
                                                <option value="3">{t('option')} 3</option>
                                                <option value="4">{t('option')} 4</option>
                                            </select>
                                        </div>
                                        <div className="h-[140vh] grid grid-cols-2 gap-4 w-full mob:grid-cols-1 tab:gap-8 tab:h-full">
                                            {displayedCourses.map((course) => (
                                                <CourseBox key={course.id} course={course} className={`w-full h-[19rem] object-cover`} />
                                            ))}
                                        </div>
                                        <div className="flex justify-center gap-4 my-8">
                                            <button
                                                onClick={() => handlePageChange(currentPage - 1)}
                                                disabled={currentPage === 1}
                                                className={`rounded-lg f-rg bg-gray-600 text-white px-4 py-2 ${currentPage === 1 ? 'opacity-40 cursor-not-allowed' : 'opacity-100 cursor-pointer hover:bg-gray-500 transition-all duration-200'}`}
                                            >
                                                {t('prev')}
                                            </button>
                                            {[...Array(totalPages)].map((_, index) => (
                                                <button
                                                    key={index}
                                                    onClick={() => handlePageChange(index + 1)}
                                                    className={`rounded-full px-4 py-2 ${currentPage === index + 1 ? 'bg-red-500 text-white' : ''}`}
                                                >
                                                    {index + 1}
                                                </button>
                                            ))}
                                            <button
                                                onClick={() => handlePageChange(currentPage + 1)}
                                                disabled={currentPage === totalPages}
                                                className={`rounded-lg f-rg bg-gray-600 text-white px-4 py-2 ${currentPage === totalPages ? 'cursor-not-allowed opacity-40' : 'opacity-100 cursor-pointer hover:bg-gray-500 transition-all duration-200'}`}
                                            >
                                                {t('next')}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </main>
                        )
                    }
                </main >
            </main >
        </>
    );
};

export default Courses;