const FormValidation = ({ theSeenPackage }) => {
    return (
        <section className="shadow-md rounded-lg p-4 w-[26rem] mob:w-full">
            <div>
                <h1 className="text-center f-black text-3xl">{` ${theSeenPackage?.price} Riyal`} </h1>
                <div className="flex justify-center">
                    <p className=" bg-blue-200 w-fit p-1 rounded-lg text-blue-600">View detailed order</p>
                </div>
            </div>
            <hr className="my-4" />
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <input type="radio" name="paying" id="paying" className="w-10 h-4" />
                    <label htmlFor="paying" className="f-bold mx-1">Credit/Debit Card</label>
                </div>
                <div>
                    <img src="/icons/visa-mastercard.svg" alt="visa" />
                </div>
            </div>
            <div className="py-2 px-4 bg-[#F3F3F3] rounded-lg my-4">
                <p className="w-11/12 m-0">Pay securely with your Bank Account using Visa or Mastercard</p>
            </div>
            <div>
                <form action="#">
                    {/* LABEL */}
                    <div className="relative">
                        <label htmlFor="label" className="absolute -top-3 left-3 bg-white px-2 text-sm">Label</label>
                        <input type="number" placeholder="XXXX XXXX XXXX XXXX" className="outline-none rounded-2xl py-2 px-3 border-2 w-full"
                            required
                            maxLength={16} />
                    </div>
                    {/* NAME ON CARD */}
                    <div className="relative my-8">
                        <label htmlFor="label" className="absolute -top-3 left-3 bg-white px-2 text-sm">Name on Card</label>
                        <input type="text" placeholder="Name on Card" className="outline-none rounded-2xl py-2 px-3 border-2 w-full"
                            required
                            maxLength={16} />
                    </div>
                    {/* DATE & CVV */}
                    <div className="flex items-center gap-4 mob:flex-col mob:items-start">
                        <div className="relative w-full">
                            <label htmlFor="label" className="absolute -top-3 left-3 bg-white px-2 text-sm">Expire Data</label>
                            <input type="date" placeholder="MM/YY" className="outline-none rounded-2xl py-2 px-3 border-2 w-full"
                                required
                            />
                        </div>
                        <div className="relative w-full">
                            <label htmlFor="label" className="absolute -top-3 left-3 bg-white px-2 text-sm">CVV Code</label>
                            <input type="password" placeholder="XXX" className="outline-none rounded-2xl py-2 px-3 border-2 w-full"
                                required
                            />
                        </div>
                    </div>
                </form>
                <div className="my-4 flex items-center gap-2">
                    <input type="checkbox" value={`save`} className="w-4 h-4" />
                    <label htmlFor="save" className="text-sm">Save card for future payments</label>
                </div>
                <hr />
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <input type="radio" name="paying" id="paying" className="w-4 h-4" />
                        <label htmlFor="paying" className="f-bold mx-3">PayPal</label>
                    </div>
                    <div>
                        <img src="/icons/paypal.png" alt="paypal" title="PayPal" className="w-20" />
                    </div>
                </div>
                <div className="py-2 text-sm px-4 bg-[#F3F3F3] rounded-lg my-4 font-base">
                    <p className="m-0">You will be redirected to PayPal website to complete your order securely.</p>
                </div>
                <div>
                    <button className="w-full bg-red-600 text-white p-2 rounded-lg">
                        Place Order
                    </button>
                </div>
            </div>
        </section>
    )
}
export default FormValidation