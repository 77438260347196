import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HomeLoading } from "../store/slices/HomeSlice";
import LoadingComponent from "../UI/CustomUI/LoadingComponent";
import { SendVerificationCode, VeriErr, VeriLoading } from "../store/slices/ForgetPassSlice";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

const ForgetPass = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loggedInMSG, setLoggedInMSG] = useState(false);
    const [saveDT, setSaveDT] = useState(false);
    const homeLoading = useSelector(HomeLoading);
    const veriLoading = useSelector(VeriLoading);
    const veriErr = useSelector(VeriErr)
    const [err, setErr] = useState(null);
    const [mail, setMail] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const AlertWhenRefresh = (event) => {
            if (!saveDT || mail !== '') {
                const confirmationMessage = "Are you sure you want to leave this page? Unsaved changes will be lost.";
                event.returnValue = confirmationMessage;
                return confirmationMessage;
            }
        };

        window.addEventListener('beforeunload', AlertWhenRefresh);

        return () => {
            window.removeEventListener('beforeunload', AlertWhenRefresh);
        };
    }, [saveDT, mail]);

    useEffect(() => {
        if (Cookies.get('token')) {
            setLoggedInMSG(true)
            const loggedInTimer = setTimeout(() => {
                navigate('/profile')
            }, 4000)
            return () => clearTimeout(loggedInTimer)
        }
    }, [navigate, veriErr])

    const SubFunc = async (e) => {
        e.preventDefault();
        try {
            const result = await dispatch(SendVerificationCode(mail));
            if (SendVerificationCode.rejected.match(result)) {
                setErr(result.payload);
            } else {
                navigate('/forget-password/verification')
                setErr(null);
            }
        } catch (error) {
            setErr('Not True Data');
        }
    }

    if (homeLoading) return (<LoadingComponent parentClass={`grid p-2 w-full`} childClass={[
        'h-[4rem] rounded-lg w-full',
        'h-[3rem] my-3 rounded-lg w-full',
        'h-[28rem] rounded-lg w-full',
    ]} childrenNumber={3} />);


    return (
        <>
            {
                loggedInMSG ?
                    <div className="f-bold h-dvh w-full text-3xl tab:text-2xl flex flex-col gap-4 justify-center items-center bg-white z-40">
                        <div className="text-center">
                            {t('uCanEdit')}
                        </div>
                        <div className="text-center">
                            {t('navigateYou')}
                        </div>
                    </div>
                    :
                    <>
                        <main className="py-5 px-6 mob:px-2">
                            <div className="flex flex-col items-center w-fit mx-6 tab:mx-0">
                                <h1 className="f-ex-bold text-2xl ">{t('forgetPassIntro')}</h1>
                                <div>
                                    <img src="/imgs/curve.png" alt="curve" className="w-auto" />
                                </div>
                            </div>
                            <section className="flex justify-center items-center my-5">
                                <section className="bg-[#F7F7FA] my-22 mx-auto p-4 shadow-md border rounded w-[43rem] tab:w-full">
                                    <p className="f-semi-bold mx-3 my-2 mb-5 text-gray-500 w-[23rem] mob:w-auto">
                                        {t('orderOne')}
                                    </p>
                                    {/* FORM */}
                                    <form method="POST" onSubmit={SubFunc} className="pr-2">
                                        {/* EMAIL  */}
                                        <div className="px-2">
                                            <label htmlFor="email" className="f-bold text-xl">
                                                {t('email')}
                                            </label>
                                            <br />
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={mail}
                                                onChange={(e) => setMail(e.target.value)}
                                                placeholder={t('writeEmail')}
                                                className="my-2 border rounded-sm outline-none f-rg px-2 py-1 shadow-sm w-full"
                                                required
                                                aria-required={true}
                                            />
                                        </div>
                                        <div>
                                            <button className="submit-btn my-4" type="submit" onClick={() => setSaveDT(true)}>
                                                {
                                                    veriLoading ? (
                                                        <div className="flex justify-center">
                                                            <span className="mx-2 f-bold"
                                                            >{t("plsWait")}</span>
                                                            <div className="animate-spin rounded-full border-t-4 border-b-4 border-white h-7 w-7 mr-2"></div>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <span className="mx-2 f-bold">{t('confirmCode')}</span>{" "}
                                                            <i className="fa-solid fa-arrow-left"></i>
                                                        </div>
                                                    )}
                                            </button>
                                        </div>
                                    </form>
                                </section>
                            </section>
                            {
                                (err || veriErr) && <div className="text-xl text-center text-red-500 font-bold f-bold my-4">
                                    <p>404 | ERROR</p>

                                    <p>{veriErr}</p>
                                </div>
                            }

                        </main>
                    </>
            }

        </>
    )
}

export default ForgetPass